<template>
  <div>
    <v-card>
      <v-form
        ref="filterTopicForm"
        @submit.prevent="handleSubmitForm"
      >
        <v-card-title>
          {{ $t('ListTopic') }}
        </v-card-title>

        <v-card-text>
          <v-select
            v-model="status"
            dense
            outlined
            :label="$t('Status')"
            :items="[
              {text: $t('All'), value: 0},
              {text: $t('Approved'), value: 2},
              {text: $t('WaitApproval'), value: 3},
              {text: $t('Rejected'), value: 5},
            ]"
            hide-details="auto"
          ></v-select>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="keyword"
            :label="$t('TopicName')"
            outlined
            dense
            :placeholder="$t('TopicName')"
            hide-details="auto"
          >
          </v-text-field>
        </v-card-text>

        <v-card-text>
          <DatePicker
            v-model="startDate"
            :label="$t('StartTime')"
          />
        </v-card-text>
        <v-card-text>
          <DatePicker
            v-model="endDate"
            :label="$t('EndTime')"
          />
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="d-flex align-center mt-1 pb-0"
            >
              <v-btn
                color="primary"
                block
                type="submit"
                :loading="fetching"
              >
                {{ $t('Search') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex align-center mt-1"
            >
              <v-btn
                color="primary"
                outlined
                block
                @click="showQrCode = true"
              >
                {{ $t('SearchByQrCode') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
    <v-card class="mt-4">
      <div
        v-infinite-scroll="loadMoreTopics"
        infinite-scroll-disabled="infinityScrollBusy"
      >
        <v-list v-if="topics.length > 0">
          <template
            v-for="(item, index) in topics"
          >
            <v-list-item

              :key="item.id_topic"
            >
              <router-link
                style="text-decoration: none; color: unset; width: 100%"
                :to="{name: 'staff-skill-share-detail', params: {id: item.id_topic}}"
              >
                <v-list-item-content>
                  <v-list-item-title
                    style="white-space: break-spaces; text-overflow: unset; overflow:visible"
                    class="mb-2 justify-space-between d-flex primary--text"
                  >
                    <strong style="max-width: 65%">{{ item.name }}</strong>

                    <v-chip
                      :color="getTopicStatusColor(item.status_label)"
                      small
                      :class="`v-chip-light-bg ${getTopicStatusColor(item.status_label)}--text`"
                    >
                      {{ $t(getTopicStatus(item.status_label)) }}
                    </v-chip>
                  </v-list-item-title>
                  <div class="mb-1">
                    <span class="text--primary">
                      <v-icon
                        size="14"
                        class="mr-1"
                      >{{ icons.mdiAccountVoice }}</v-icon><span class="text-xs ms-1">{{ item.speaker_name }}</span>
                    </span>
                  </div>
                  <div class="mb-1">
                    <v-icon
                      size="14"
                      class="mr-1"
                    >
                      {{ icons.mdiCalendarBlankOutline }}
                    </v-icon>
                    <span class="text-xs ms-1">{{
                      enrichTopicTime(
                        formatTimestampToDatetime(item.start_time, 'DD/MM/YYYY HH:mm'),
                        formatTimestampToDatetime(item.end_time, 'DD/MM/YYYY HH:mm')
                      )
                    }}
                    </span>
                  </div>
                  <div class="mb-1">
                    <v-icon
                      size="14"
                      class="mr-1"
                    >
                      {{ icons.mdiAccountMultiple }}
                    </v-icon><span class="text-xs ms-1">{{ item.participants }} / {{ item.max_seat }}</span>
                  </div>
                </v-list-item-content>
              </router-link>
            </v-list-item>
            <v-divider
              v-if="index < topics.length - 1"
              :key="`divider-${item.id_topic}`"
              :inset="item.inset"
            ></v-divider>
          </template>
        </v-list>
        <div
          v-else
          class="text-center pb-2"
        >
          {{ $t("NoData") }}
        </div>
      </div>
    </v-card>
    <StaffSkillShareQrCode :show-qr-code.sync="showQrCode" />
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import SkillShareService from '@/services/SkillShareService'
import { enrichTopicTime, getStatusParams, getTopicStatus, getTopicStatusColor } from '@/utils/skillShareHelper'
import { mdiAccountMultiple, mdiAccountVoice, mdiCalendarBlankOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import _ from 'lodash'
import infiniteScroll from 'vue-infinite-scroll'
import StaffSkillShareQrCode from './components/StaffSkillShareQrCode.vue'

export default {
  components: { DatePicker, StaffSkillShareQrCode },
  setup() {
    const showQrCode = ref(false)
    const infinityScrollBusy = ref(false)
    const stopLoadMoreTopics = ref(false)
    const filterTopicForm = ref(null)
    const startDate = ref('')
    const endDate = ref('')
    const status = ref(0)
    const keyword = ref('')
    const page = ref(1)
    const limit = 10
    const fetching = ref(false)
    const topics = ref([])

    const getData = async () => {
      const statusParams = getStatusParams(status.value)
      const params = {
        from_date: startDate.value,
        to_date: endDate.value,
        keyword: keyword.value,
        page: page.value,
        limit,
        ...statusParams,
      }

      try {
        fetching.value = true
        const resp = await SkillShareService.getListTopic(params)

        const { data } = resp.data
        if (data.length === 0) {
          stopLoadMoreTopics.value = true
        } else {
          topics.value = [...topics.value, ...data]
          page.value += 1
          stopLoadMoreTopics.value = false
        }
      } catch {
      } finally {
        fetching.value = false
      }
    }

    const loadMoreTopics = _.debounce(async () => {
      if (!stopLoadMoreTopics.value) {
        infinityScrollBusy.value = true
        await getData()
        infinityScrollBusy.value = false
      }
    }, 200)

    const handleSubmitForm = async () => {
      page.value = 1
      topics.value = []
      await getData()
    }

    return {
      showQrCode,
      filterTopicForm,
      fetching,
      startDate,
      endDate,
      status,
      keyword,
      topics,
      handleSubmitForm,
      loadMoreTopics,
      getTopicStatus,
      getTopicStatusColor,
      enrichTopicTime,

      icons: {
        mdiCalendarBlankOutline,
        mdiAccountMultiple,
        mdiAccountVoice,
      },
    }
  },
  directives: { infiniteScroll },
}
</script>
