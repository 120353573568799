<template>
  <v-dialog
    v-model="showQrCode"
    max-width="750px"
    persistent
  >
    <v-card>
      <template>
        <v-card-title>
          {{ $t('SearchByQrCode') }}
        </v-card-title>

        <v-card-text>
          <QRCode
            v-model="qrData"
            class="qrcode"
          />
        </v-card-text>

        <v-card-text>
          <v-btn
            outlined
            block
            class="mt-4 mb-8"
            @click="$emit('update:showQrCode', false)"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.qrcode {
  width: 100% !important;
  height: 100% !important;
  padding: 2px;
}
</style>

<script>
import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import QRCode from '@/components/QRCode.vue'

export default {
  components: {
    QRCode,
  },
  props: {
    showQrCode: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const qrData = ref(null)
    const { router } = useRouter()

    watch(qrData, value => {
      if (value) {
        const topicId = qrData.value
        router.push({ name: 'staff-skill-share-detail', params: { id: topicId } })
        qrData.value = null
      }
    })

    return {
      qrData,
    }
  },
}
</script>
